import {
  ACTION_FETCH_LEVELS,
  ACTION_FETCH_LEVEL_DETAILS,
  ACTION_UPDATE_LEVEL,
  ACTION_SET_LEVELS,
  ACTION_DELETE_LEVEL,
  ACTION_SET_LEVEL_DETAILS,
  ACTION_CREATE_LEVEL,
  ACTION_SET_LEVEL_MODULE_STATES,
  ACTION_PUBLISH_LEVEL,
  ACTION_SET_LEVEL_SECTION_DETAILS,
  ACTION_UPDATE_LEVEL_SECTION, ACTION_CREATE_LEVEL_SECTION,
} from './actionTypes';

export const fetchLevelsEvent = () => ({
  type: ACTION_FETCH_LEVELS,
});

export const setLevelsEvent = (levels) => ({
  type: ACTION_SET_LEVELS,
  payload: levels,
});

export const fetchLevelDetailsEvent = (levelId) => ({
  type: ACTION_FETCH_LEVEL_DETAILS,
  payload: { levelId },
});

export const setLevelDetailsEvent = (levelData) => ({
  type: ACTION_SET_LEVEL_DETAILS,
  payload: { levelData },
});

export const setLevelModuleStatesEvent = (levelStates) => ({
  type: ACTION_SET_LEVEL_MODULE_STATES,
  payload: { levelStates },
});

export const updateLevelEvent = (levelId, data) => ({
  type: ACTION_UPDATE_LEVEL,
  payload: { levelId, data },
});

export const createLevelEvent = (data) => ({
  type: ACTION_CREATE_LEVEL,
  payload: { data },
});

export const publishLevelEvent = (levelId) => ({
  type: ACTION_PUBLISH_LEVEL,
  payload: levelId ,
});

export const deleteLevelEvent = (levelId) => ({
  type: ACTION_DELETE_LEVEL,
  payload: { levelId },
});


// Level Sections
export const setLevelSectionDetailsEvent = (levelSectionData) => ({
  type: ACTION_SET_LEVEL_SECTION_DETAILS,
  payload: {levelSectionData}
});

export const updateLevelSectionEvent = (levelSectionId, data) => ({
  type: ACTION_UPDATE_LEVEL_SECTION,
  payload: { levelSectionId, data },
});

export const createLevelSectionEvent = (data) => ({
  type: ACTION_CREATE_LEVEL_SECTION,
  payload: { data },
});
