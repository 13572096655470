import {
  ACTION_UPDATE_LAYOUT,
  ACTION_CREATE_LAYOUT,
  ACTION_SET_LAYOUT_DETAILS,
  ACTION_SET_LAYOUT_DETAILS_IS_LOADED,
  ACTION_DELETE_LAYOUT,
  ACTION_FETCH_LAYOUTS,
  ACTION_SET_LAYOUTS,
  ACTION_FETCH_LAYOUT_DETAILS, ACTION_SET_LAYOUT_EDIT_MODE,
} from './actionTypes';

export const updateLayoutEvent = (layoutId, data) => ({
  type: ACTION_UPDATE_LAYOUT,
  payload: { layoutId, data },
});

export const createLayoutEvent = (data) => ({
  type: ACTION_CREATE_LAYOUT,
  payload: { data },
});

export const setLayoutDetailsEvent = (data) => ({
  type: ACTION_SET_LAYOUT_DETAILS,
  payload: { data },
});

export const setLayoutsEvent = (layouts) => ({
  type: ACTION_SET_LAYOUTS,
  payload: { layouts },
});

export const setLayoutDetailsIsLoadedEvent = (isLoaded) => ({
  type: ACTION_SET_LAYOUT_DETAILS_IS_LOADED,
  payload: { isLayoutDetailsLoaded: isLoaded },
});

export const deleteLayoutEvent = (layoutId) => ({
  type: ACTION_DELETE_LAYOUT,
  payload: { layoutId },
});
export const fetchLayoutsEvent = (gameId) => ({
  type: ACTION_FETCH_LAYOUTS,
  payload: { gameId },
});

export const fetchLayoutDetailsEvent = (layoutId) => ({
  type: ACTION_FETCH_LAYOUT_DETAILS,
  payload: { layoutId },
});
