import {
  ACTION_REQUEST_SEND,
  ACTION_REQUEST_RECEIVE,
} from '../Actions/actionTypes';

const INIT_STATE = {
  isLoading: false,
};

const System = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_REQUEST_SEND:
      return {
        ...state,
        isLoading: true,
      };

    case ACTION_REQUEST_RECEIVE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default System;
