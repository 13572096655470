import React, { lazy } from 'react';
import {
  ROUTE_SETTINGS_WITH_LAYOUT_CREATE_PAGE,
  ROUTE_SETTINGS_WITH_LAYOUT_EDIT_PAGE,
} from '../../../../Ship/Constants/urls';

const SettingsWithLayoutCreateEditPage = lazy(() => import('../Components/Pages/SettingSafeCreateEditPage/SettingSafeCreateEditPage'));

const settingManagementRoutes = [
  {
    path: ROUTE_SETTINGS_WITH_LAYOUT_EDIT_PAGE,
    element: <SettingsWithLayoutCreateEditPage isEditMode={true} />,
  },
  {
    path: ROUTE_SETTINGS_WITH_LAYOUT_CREATE_PAGE,
    element: <SettingsWithLayoutCreateEditPage isEditMode={false} />,
  }
];

export default settingManagementRoutes;
