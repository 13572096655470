import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function notify({ type, title, message }) {
  toastr.options = {
    positionClass: 'toast-top-right',
    timeOut: 3000,
    extendedTimeOut: 3000,
    closeButton: true,
    behaviorButton: true,
    // debug: debug,
    progressBar: false,
    // preventDuplicates: preventDuplicates,
    newestOnTop: true,
    // showEasing: showEasing,
    // hideEasing: hideEasing,
    // showMethod: showMethod,
    // hideMethod: hideMethod,
    // showDuration: showDuration,
    // hideDuration: hideDuration,
  };

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  // Toaster Types
  if (type === 'info') toastr.info(message, title);
  else if (type === 'warning') toastr.warning(message, title);
  else if (type === 'error') toastr.error(message, title);
  else toastr.success(message, title);

  return true;
}

export default notify;
