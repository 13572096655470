import {
  ACTION_SET_GAME_DETAILS,
  ACTION_SET_GAME_TOKEN,
  ACTION_SET_GAMES,
  ACTION_SET_GAME_DETAILS_IS_LOADED,
} from './actionTypes';

const INIT_STATE = {
  games: [],
  gameTokenData: {
    isGameTokenCreated: false,
    token: null,
  },
  gameDetails: {
    name: null,
    genre: null,
  },
  moduleStates: {
    isGameDetailsLoaded: false,
  },
};

const GameManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_GAMES:
      return {
        ...state,
        games: action.payload.games,
      };

    case ACTION_SET_GAME_TOKEN:
      return {
        ...state,
        gameTokenData: {
          isGameTokenCreated: action.payload.isGameTokenCreated,
          token: action.payload.gameToken,
        },
      };

    case ACTION_SET_GAME_DETAILS:
      return {
        ...state,
        gameDetails: {
          name: action.payload.gameData.name,
          genre: action.payload.gameData.genre,
        },
      };

    case ACTION_SET_GAME_DETAILS_IS_LOADED:
      return {
        ...state,
        moduleStates: {
          isGameDetailsLoaded: action.payload.isGameDetailsLoaded,
        },
      };

    default:
      return state;
  }
};

export default GameManagement;
