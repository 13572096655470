class RemoteFile {
  settings = {};
  link = '';
  
  constructor({link, settings}) {
    this.link = link;
    this.settings = settings;
  }
  
  static buildFrom(data) {
    const {settings, link} = data;
    
    return new RemoteFile({link, settings});
  }
}

export default RemoteFile;