import {call, put, takeEvery} from 'redux-saga/effects';

import {
  ACTION_CREATE_GAME_RESOURCE, ACTION_DELETE_GAME_RESOURCE,
  ACTION_FETCH_GAME_RESOURCE_DETAILS,
  ACTION_FETCH_GAME_RESOURCES,
  ACTION_UPDATE_GAME_RESOURCE,
} from './actionTypes';
import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_FETCH_GAME_RESOURCES,
  API_UPDATE_GAME_RESOURCE,
  API_CREATE_GAME_RESOURCE,
  API_FETCH_GAME_RESOURCE_DETAILS, API_DELETE_GAME_RESOURCE,
} from '../Constants/ApiRoutes';
import {
  fetchGameResourcesEvent,
  setGameResourceDetailsEvent,
  setGameResourcesEvent
} from './actions';
import notify from '../../../../Ship/Helpers/Notification';
import GameResource from "../Models/GameResource";
import RemoteFile from "../../../../Ship/Models/RemoteFile";
import {gameResourceFileTypes} from "../Constants/GameResourceFileTypes";

function* fetchGameResourcesWorker({payload: {offset, resourceType, limit}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      ({limit, offset, resourceType}) => {
        return apiHelper.get(
          encodeURI(
            API_FETCH_GAME_RESOURCES + `?limit=${limit}&offset=${offset}&type=${resourceType}`
          )
        )
      },
      {
        limit,
        offset,
        resourceType
      }
    );

    yield put(
      setGameResourcesEvent(
        response.data.map(gameResource => GameResource.buildFrom(gameResource))
      )
    );
  } catch (error) {
    console.error('An error occurred while fetching game resources:', error);
  }
}


function* deleteGameResourceEvent({ payload: { gameResourceId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    console.log(gameResourceId);
    
    const response = yield call(
      (gameResource) => apiHelper
        .del(
          API_DELETE_GAME_RESOURCE.replace('{game_resource_uuid}', gameResource.id),
        ),
      {
        id: gameResourceId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game was deleted, the list is being updating',
        },
      );

      yield put(fetchGameResourcesEvent(gameResourceFileTypes.IMAGE));
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while deleting the game.',
    });
  }
}


function* updateGameResourceWorker({payload: {gameResourceId, data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const formData = new FormData();
    
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('status', data.status);

    data.tags.forEach(tag => {
      formData.append('tags[]', tag.name);
    });

    if (data.selectedFile instanceof File) {
      formData.append('game_resource', data.selectedFile);
    }
    
    const response = yield call(
      () => {
        apiHelper
          .post(
            API_UPDATE_GAME_RESOURCE.replace('{game_resource_uuid}', gameResourceId),
            formData,
          )
      }
    );

    notify(
      {
        type: 'success',
        title: 'Success',
        message: 'The resource was updated, the list is being updating',
      },
    );

    yield put(fetchGameResourcesEvent(gameResourceFileTypes.IMAGE));
  } catch (error) {
    console.log(error);
    
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the game resource.',
    });
  }
}

function* createGameResourceWorker({payload: {data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const formData = new FormData();

    formData.append('game_resource', data.selectedFile);
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('status', data.status);

    data.tags.forEach(tag => {
      formData.append('tags[]', tag.name);
    });

    const response = yield call(
      () => apiHelper
        .post(
          API_CREATE_GAME_RESOURCE,
          formData,
        ),
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game was created, the list is being updating',
        },
      );

      yield put(fetchGameResourcesEvent(gameResourceFileTypes.IMAGE));
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the game.',
    });
  }
}

function* fetchGameResourceDetailsWorker({payload: {gameResourceId}}) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (gameResource) => apiHelper
        .get(
          API_FETCH_GAME_RESOURCE_DETAILS.replace('{game_resource_uuid}', gameResource.id),
        ),
      {
        id: gameResourceId,
      },
    );
    yield put(setGameResourceDetailsEvent(
      {
        tags: response.data.tags,
        selectedFile: RemoteFile.buildFrom(
          {
            link: response.data.link,
            settings: response.data.settings
          }
        ),
        name: response.data.name,
      }
    ));
  } catch (error) {

  }
}

function* gamesSagaWatcher() {
  yield takeEvery(ACTION_UPDATE_GAME_RESOURCE, updateGameResourceWorker);
  yield takeEvery(ACTION_DELETE_GAME_RESOURCE, deleteGameResourceEvent);
  yield takeEvery(ACTION_FETCH_GAME_RESOURCE_DETAILS, fetchGameResourceDetailsWorker);
  yield takeEvery(ACTION_CREATE_GAME_RESOURCE, createGameResourceWorker);
  yield takeEvery(ACTION_FETCH_GAME_RESOURCES, fetchGameResourcesWorker);
}

export default gamesSagaWatcher;
