import React, { lazy } from 'react';

const DashboardPage = lazy(() => import('../Components/Pages/DashboardPage'));

const dashboardRoutes = [
  {
    path: '/dashboard',
    element: <DashboardPage />,
  },
];

export default dashboardRoutes;
