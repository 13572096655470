import React, { lazy } from 'react';
import { ROUTE_USERS_PAGE } from '../../../Ship/Constants/urls';

const UserManagementPage = lazy(() => import('../Components/Pages/UserManagementPage'));

const userManagementRoutes = [
  {
    path: ROUTE_USERS_PAGE,
    element: <UserManagementPage />,
  },
];
export default userManagementRoutes;
