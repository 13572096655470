import React, { lazy } from 'react';
import {
  ROUTE_PLAYERS_PAGE,
} from '../../../../Ship/Constants/urls';

const PlayerManagementPage = lazy(() => import('../Components/Pages/PlayerManagementPage'));

const PlayerRoutes = [
  {
    path: ROUTE_PLAYERS_PAGE,
    element: <PlayerManagementPage />,
  },
];

export default PlayerRoutes;
