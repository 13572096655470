import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Redux Store
import { withTranslation } from 'react-i18next';
import { showRightSidebarAction, toggleLeftmenu } from '../Store/layout/actions';

// Import menuDropdown
import LanguageDropdown from './LanguageDropdown';
import NotificationDropdown from './NotificationDropdown';
import ProfileMenu from './ProfileMenu';

import logo from '../Assets/images/logo.svg';
import logoLight from '../Assets/images/logo-light.png';
import logoLightSvg from '../Assets/images/logo-light.svg';
import logoDark from '../Assets/images/logo-dark.png';

// i18n

function Header({ leftMenu = false }) {
  return (
    <header id="page-topbar">
      <div style={{ maxWidth: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoDark} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="19" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                toggleLeftmenu(!leftMenu);
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>

          <div className="d-flex">
            <LanguageDropdown />

            <NotificationDropdown />

            <ProfileMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  leftMenu: PropTypes.bool,
};

const mapStatetoProps = () => {
  const { layoutType, showRightSidebar, leftMenu } = {
    layoutType: 'horizontal',
    showRightSidebar: false,
    leftMenu: true,
  };
  return { layoutType, showRightSidebar, leftMenu };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header));
