import {
  ACTION_REQUEST_SEND,
  ACTION_REQUEST_RECEIVE,
} from './actionTypes';

export const sendRequestEvent = () => ({
  type: ACTION_REQUEST_SEND,
});

export const receivedRequestEvent = () => ({
  type: ACTION_REQUEST_RECEIVE,
});
