import {
  ACTION_SET_SETTING_DETAILS,
  ACTION_SET_SETTING_DETAILS_IS_LOADED,
  ACTION_SET_SETTING_ERRORS,
  ACTION_SET_SETTINGS,
} from './actionTypes';

const INIT_STATE = {
  settings: [],
  settingDetails: {
    name: '',
    schema: '[]',

  },
  moduleStates: {
    isSettingDetailsLoaded: false,
    errors: [],
  },
};

const SettingManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    case ACTION_SET_SETTING_ERRORS:
      return {
        ...state,
        moduleStates: {
          ...state.moduleStates,
          errors: action.payload.errors
        }
      };

    case ACTION_SET_SETTING_DETAILS:
      return {
        ...state,
        settingDetails: {
          name: action.payload.data.name,
          structure_id: action.payload.data.structure_id,
          schema: action.payload.data.schema,
          author_id: action.payload.data.author_id,
        },
      };

    case ACTION_SET_SETTING_DETAILS_IS_LOADED:
      return {
        ...state,
        moduleStates: {
          ...state.moduleStates,
          isSettingDetailsLoaded: action.payload.isSettingDetailsLoaded,
        },
      };

    default:
      return state;
  }
};

export default SettingManagement;
