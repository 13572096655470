export const ROLES = {
  ADMIN: 'admin',
  COMMON_CUSTOMER: 'common_customer',
  PRIVILEGED_CUSTOMER: 'privileged_customer',
  GUEST: 'guest',
};

export const PERMISSIONS = {
  GAME_FULL_OWN_READ: 'game-full-own-read',
  GAME_FULL_OTHER_READ: 'game-full-other-read',
  GAME_PUBLIC_OWN_READ: 'game-public-own-read',
  GAME_PUBLIC_OTHER_READ: 'game-public-other-read',
  GAME_FULL_OWN_UPDATE: 'game-full-own-update',
  GAME_FULL_OTHER_UPDATE: 'game-full-other-update',
  GAME_PUBLIC_OWN_UPDATE: 'game-public-own-update',
  GAME_PUBLIC_OTHER_UPDATE: 'game-public-other-update',
  GAME_FULL_OWN_CREATE: 'game-full-own-create',
  GAME_FULL_OTHER_CREATE: 'game-full-other-create',
  GAME_FULL_OWN_DELETE: 'game-full-own-delete',
  GAME_FULL_OTHER_DELETE: 'game-full-other-delete',
  PLAYER_FULL_OWN_READ: 'player-full-own-read',
  PLAYER_FULL_OTHER_READ: 'player-full-other-read',
  PLAYER_PUBLIC_OWN_READ: 'player-public-own-read',
  PLAYER_PUBLIC_OTHER_READ: 'player-public-other-read',
  PLAYER_FULL_OWN_UPDATE: 'player-full-own-update',
  PLAYER_FULL_OTHER_UPDATE: 'player-full-other-update',
  PLAYER_PUBLIC_OWN_UPDATE: 'player-public-own-update',
  PLAYER_PUBLIC_OTHER_UPDATE: 'player-public-other-update',
  PLAYER_FULL_OWN_CREATE: 'player-full-own-create',
  PLAYER_FULL_OTHER_CREATE: 'player-full-other-create',
  PLAYER_FULL_OWN_DELETE: 'player-full-own-delete',
  PLAYER_FULL_OTHER_DELETE: 'player-full-other-delete',
  USER_FULL_OWN_READ: 'user-full-own-read',
  USER_FULL_OTHER_READ: 'user-full-other-read',
  USER_PUBLIC_OWN_READ: 'user-public-own-read',
  USER_PUBLIC_OTHER_READ: 'user-public-other-read',
  USER_FULL_OWN_UPDATE: 'user-full-own-update',
  USER_FULL_OTHER_UPDATE: 'user-full-other-update',
  USER_PUBLIC_OWN_UPDATE: 'user-public-own-update',
  USER_PUBLIC_OTHER_UPDATE: 'user-public-other-update',
  USER_FULL_OWN_CREATE: 'user-full-own-create',
  USER_FULL_OTHER_CREATE: 'user-full-other-create',
  USER_FULL_OWN_DELETE: 'user-full-own-delete',
  USER_FULL_OTHER_DELETE: 'user-full-other-delete',
};

export const PERMISSIONS_TO_ROLES_MAP = {
  [ROLES.ADMIN]: [
    PERMISSIONS.GAME_FULL_OWN_READ,
    PERMISSIONS.GAME_FULL_OTHER_READ,
    PERMISSIONS.GAME_PUBLIC_OWN_READ,
    PERMISSIONS.GAME_PUBLIC_OTHER_READ,

    PERMISSIONS.GAME_FULL_OWN_UPDATE,
    PERMISSIONS.GAME_FULL_OTHER_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OWN_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.GAME_FULL_OWN_CREATE,
    PERMISSIONS.GAME_FULL_OTHER_CREATE,

    PERMISSIONS.GAME_FULL_OWN_DELETE,
    PERMISSIONS.GAME_FULL_OTHER_DELETE,

    PERMISSIONS.PLAYER_FULL_OWN_READ,
    PERMISSIONS.PLAYER_FULL_OTHER_READ,
    PERMISSIONS.PLAYER_PUBLIC_OWN_READ,
    PERMISSIONS.PLAYER_PUBLIC_OTHER_READ,

    PERMISSIONS.PLAYER_FULL_OWN_UPDATE,
    PERMISSIONS.PLAYER_FULL_OTHER_UPDATE,
    PERMISSIONS.PLAYER_PUBLIC_OWN_UPDATE,
    PERMISSIONS.PLAYER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.PLAYER_FULL_OWN_CREATE,
    PERMISSIONS.PLAYER_FULL_OTHER_CREATE,

    PERMISSIONS.PLAYER_FULL_OWN_DELETE,
    PERMISSIONS.PLAYER_FULL_OTHER_DELETE,

    PERMISSIONS.USER_FULL_OWN_READ,
    PERMISSIONS.USER_FULL_OTHER_READ,
    PERMISSIONS.USER_PUBLIC_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OTHER_READ,

    PERMISSIONS.USER_FULL_OWN_UPDATE,
    PERMISSIONS.USER_FULL_OTHER_UPDATE,
    PERMISSIONS.USER_PUBLIC_OWN_UPDATE,
    PERMISSIONS.USER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.USER_FULL_OTHER_CREATE,

    PERMISSIONS.USER_FULL_OWN_DELETE,
    PERMISSIONS.USER_FULL_OTHER_DELETE,
  ],

  [ROLES.COMMON_CUSTOMER]: [
    PERMISSIONS.GAME_FULL_OWN_READ,
    PERMISSIONS.GAME_PUBLIC_OWN_READ,
    PERMISSIONS.GAME_PUBLIC_OTHER_READ,

    PERMISSIONS.GAME_FULL_OWN_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OWN_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.PLAYER_FULL_OWN_READ,
    PERMISSIONS.PLAYER_PUBLIC_OWN_READ,

    PERMISSIONS.PLAYER_FULL_OWN_UPDATE,
    PERMISSIONS.PLAYER_PUBLIC_OWN_UPDATE,

    PERMISSIONS.PLAYER_FULL_OWN_CREATE,

    PERMISSIONS.PLAYER_FULL_OWN_DELETE,

    PERMISSIONS.USER_FULL_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OTHER_READ,

    PERMISSIONS.USER_FULL_OWN_UPDATE,
    PERMISSIONS.USER_PUBLIC_OWN_UPDATE,
    PERMISSIONS.USER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.USER_FULL_OWN_CREATE,

    PERMISSIONS.USER_FULL_OWN_DELETE,
  ],

  [ROLES.PRIVILEGED_CUSTOMER]: [
    PERMISSIONS.GAME_FULL_OWN_READ,
    PERMISSIONS.GAME_PUBLIC_OWN_READ,
    PERMISSIONS.GAME_PUBLIC_OTHER_READ,

    PERMISSIONS.GAME_FULL_OWN_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OWN_UPDATE,
    PERMISSIONS.GAME_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.GAME_FULL_OWN_CREATE,

    PERMISSIONS.GAME_FULL_OWN_DELETE,

    PERMISSIONS.PLAYER_FULL_OWN_READ,
    PERMISSIONS.PLAYER_PUBLIC_OWN_READ,
    PERMISSIONS.PLAYER_PUBLIC_OTHER_READ,

    PERMISSIONS.PLAYER_FULL_OWN_UPDATE,
    PERMISSIONS.PLAYER_PUBLIC_OWN_UPDATE,
    PERMISSIONS.PLAYER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.PLAYER_FULL_OWN_CREATE,

    PERMISSIONS.PLAYER_FULL_OWN_DELETE,

    PERMISSIONS.USER_FULL_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OTHER_READ,

    PERMISSIONS.USER_FULL_OWN_UPDATE,
    PERMISSIONS.USER_PUBLIC_OWN_UPDATE,
    PERMISSIONS.USER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.USER_FULL_OTHER_CREATE,

    PERMISSIONS.USER_FULL_OWN_DELETE,
  ],

  [ROLES.GUEST]: [
    PERMISSIONS.GAME_PUBLIC_OTHER_READ,

    PERMISSIONS.GAME_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.USER_PUBLIC_OWN_READ,
    PERMISSIONS.USER_PUBLIC_OTHER_READ,

    PERMISSIONS.USER_PUBLIC_OTHER_UPDATE,

    PERMISSIONS.USER_FULL_OWN_CREATE,
  ],
};
