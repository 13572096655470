import React, {lazy} from 'react';
import {
  ROUTE_GAME_RESOURCES_LIST_PAGE,
} from '../../../../Ship/Constants/urls';

const ResourcesListPage = lazy(() => import('../Components/Pages/ResourcesListPage/ResourcesListPage'))

const GameResourceRoutes = [
  {
    path: ROUTE_GAME_RESOURCES_LIST_PAGE,
    element: <ResourcesListPage/>,
  },
];

export default GameResourceRoutes;
