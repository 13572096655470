import { PERMISSIONS_TO_ROLES_MAP } from '../Constants/authorization';

class User {
  login;

  status;

  roles;

  permissions;

  constructor({
    login, status, roles, permissions,
  }) {
    this.login = login;
    this.status = status;
    this.roles = roles;
    this.permissions = permissions;
  }

  hasRight(permission) {
    const rolesProcessed = this.roles?.map((role) => role.name) ?? [];

    const hasRoleWithPermission = rolesProcessed
      ?.some((role) => Object.prototype.hasOwnProperty.call(PERMISSIONS_TO_ROLES_MAP, role)
        && PERMISSIONS_TO_ROLES_MAP[role].includes(permission));

    if (hasRoleWithPermission) {
      return true;
    }

    return this.permissions?.some((p) => p?.name === permission);
  }
}

export default User;
