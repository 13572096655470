import React, { Suspense } from 'react';
import LoadingWrapper from '../../Parents/Components/LoadingWrapper';

const RouterFacade = {
  processProtectedRoutes(
    {
      path,
      isAuthProtected,
      children,
    },
  ) {
    if (
      isAuthProtected
            && (
              !localStorage.getItem('auth')
                || (!JSON.parse(localStorage.getItem('auth')).isSignedIn)
            )
    ) {
      if (path && path === window.location.pathname) {
        window.location.href = '/login';

        return;
      }

      if (children && children.some((child) => child.path === window.location.pathname)) {
        window.location.href = '/login';
      }
    }
  },

  process(
    {
      path,
      element,
      isAuthProtected,
      children,
      layout: Layout,
    },
  ) {
    this.processProtectedRoutes({
      path,
      isAuthProtected,
      children,
    });

    if (
      path === window.location.pathname
            && isAuthProtected
            && !localStorage.getItem('authUser')
    ) {
      window.location.href = '/login';
    }

    const result = {
      path,
    };

    if (children) {
      let updatedChildren = children;
      if (Layout) {
        updatedChildren = children
          .map(
            (child) => (
              {
                ...child,
                element: <Layout>{child.element}</Layout>,
              }
            ),
          );
      }

      result.children = updatedChildren.map(
        (child) => (
          {
            ...child,
            element:
  <LoadingWrapper>
    <Suspense fallback={<div>loading...</div>}>
      {child.element}
    </Suspense>
  </LoadingWrapper>,
          }
        ),
      );
    }

    if (element) {
      let updatedElement = element;
      if (Layout) {
        updatedElement = <Layout>{updatedElement}</Layout>;
      }

      result.element = (
        <LoadingWrapper>
          <Suspense fallback={<div>loading...</div>}>
            {updatedElement}
          </Suspense>
        </LoadingWrapper>
      );
    }

    return result;
  },
};

export default RouterFacade;
