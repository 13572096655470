import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_CREATE_PLAYER,
  ACTION_FETCH_PLAYER_DETAILS,
  ACTION_FETCH_PLAYERS,
  ACTION_UPDATE_PLAYER,
} from './actionTypes';
import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_FETCH_PLAYER_DETAILS,
  API_FETCH_PLAYERS,
  API_UPDATE_PLAYER,
  API_CREATE_PLAYER,
} from '../Constants/ApiRoutes';
import {
  fetchPlayersEvent,
  setPlayerDetailsEvent,
  setPlayerDetailsIsLoadedEvent,
  setPlayersEvent,
} from './actions';
import notify from '../../../../Ship/Helpers/Notification';

function* fetchPlayersWorker({ payload: { gameId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (player) => apiHelper.get(
        `${API_FETCH_PLAYERS}?game_uuid=${player.gameId}`,
      ),
      {
        gameId,
      },
    );

    yield put(setPlayersEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching players:', error);
  }
}

function* updatePlayerWorker({ payload: { playerId, data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (player) => apiHelper
        .put(
          API_UPDATE_PLAYER
            .replace('{player_id}', player.id),
          player.data,
        ),
      {
        id: playerId,
        data: {
          login: data.login,
          password: data.password,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The player was updated, the list is being updating',
        },
      );

      yield put(fetchPlayersEvent(data.gameUuid));
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the player.',
    });
  }
}

function* createPlayerWorker({ payload: { data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (player) => apiHelper
        .post(
          API_CREATE_PLAYER,
          player.data,
        ),
      {
        data: {
          login: data.login,
          password: data.password,
          game_uuid: data.gameUuid,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The player was created, the list is being updating',
        },
      );

      yield put(fetchPlayersEvent(data.gameUuid));
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the player.',
    });
  }
}

function* fetchPlayerDetailsWorker({ payload: { playerId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (player) => apiHelper
        .get(
          API_FETCH_PLAYER_DETAILS
            .replace('{player_id}', player.id),
        ),
      {
        id: playerId,
      },
    );

    yield put(setPlayerDetailsEvent(response.data));
    yield put(setPlayerDetailsIsLoadedEvent(true));
  } catch (error) {
    // console.error('An error occurred while fetching player:', error);
  }
}

function* playersSagaWatcher() {
  yield takeEvery(ACTION_FETCH_PLAYERS, fetchPlayersWorker);
  yield takeEvery(ACTION_UPDATE_PLAYER, updatePlayerWorker);
  yield takeEvery(ACTION_CREATE_PLAYER, createPlayerWorker);
  yield takeEvery(ACTION_FETCH_PLAYER_DETAILS, fetchPlayerDetailsWorker);
}

export default playersSagaWatcher;
