import {
  ACTION_UPDATE_SETTING,
  ACTION_CREATE_SETTING,
  ACTION_SET_SETTING_DETAILS,
  ACTION_SET_SETTING_DETAILS_IS_LOADED,
  ACTION_DELETE_SETTING,
  ACTION_FETCH_SETTINGS,
  ACTION_SET_SETTINGS,
  ACTION_FETCH_SETTING_DETAILS,
  ACTION_SET_SETTING_ERRORS,
} from './actionTypes';

export const updateSettingEvent = (settingId, data) => ({
  type: ACTION_UPDATE_SETTING,
  payload: { settingId, data },
});

export const createSettingEvent = (data) => ({
  type: ACTION_CREATE_SETTING,
  payload: { data },
});

export const setSettingDetailsEvent = (data) => ({
  type: ACTION_SET_SETTING_DETAILS,
  payload: { data },
});

export const setSettingsEvent = (settings) => ({
  type: ACTION_SET_SETTINGS,
  payload: { settings },
});

export const setSettingErrorsEvent = (errors) => ({
  type: ACTION_SET_SETTING_ERRORS,
  payload: { errors },
});

export const setSettingDetailsIsLoadedEvent = (isLoaded) => ({
  type: ACTION_SET_SETTING_DETAILS_IS_LOADED,
  payload: { isSettingDetailsLoaded: isLoaded },
});

export const deleteSettingEvent = (settingId) => ({
  type: ACTION_DELETE_SETTING,
  payload: { settingId },
});
export const fetchSettingsEvent = (gameId) => ({
  type: ACTION_FETCH_SETTINGS,
  payload: { gameId },
});

export const fetchSettingDetailsEvent = (settingId) => ({
  type: ACTION_FETCH_SETTING_DETAILS,
  payload: { settingId },
});
