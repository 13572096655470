import {
  ACTION_CREATE_GAME_RESOURCE, 
  ACTION_DELETE_GAME_RESOURCE,
  ACTION_FETCH_GAME_RESOURCE_DETAILS,
  ACTION_FETCH_GAME_RESOURCES,
  ACTION_SET_GAME_RESOURCE_DETAILS,
  ACTION_SET_GAME_RESOURCE_MODULE_STATES,
  ACTION_SET_GAME_RESOURCES,
  ACTION_UPDATE_GAME_RESOURCE,
} from './actionTypes';

export const fetchGameResourcesEvent = (type, offset, limit) => ({
  type: ACTION_FETCH_GAME_RESOURCES,
  payload: { resourceType: type, offset, limit }
});

export const setGameResourcesEvent = (gameResources) => ({
  type: ACTION_SET_GAME_RESOURCES,
  payload: { gameResources },
});

export const fetchGameResourceDetailsEvent = (gameResourceId) => ({
  type: ACTION_FETCH_GAME_RESOURCE_DETAILS,
  payload: { gameResourceId },
});

export const setGameResourceDetailsEvent = (gameResourceData) => ({
  type: ACTION_SET_GAME_RESOURCE_DETAILS,
  payload: { gameResourceData },
});

export const setGameResourceModuleStatesEvent = (moduleStates) => ({
  type: ACTION_SET_GAME_RESOURCE_MODULE_STATES,
  payload: { moduleStates },
});

export const updateGameResourceEvent = (gameResourceId, data) => ({
  type: ACTION_UPDATE_GAME_RESOURCE,
  payload: { gameResourceId, data },
});

export const createGameResourceEvent = (data) => ({
  type: ACTION_CREATE_GAME_RESOURCE,
  payload: { data },
});

export const deleteGameResourceEvent = (gameResourceId) => ({
  type: ACTION_DELETE_GAME_RESOURCE,
  payload: { gameResourceId },
});