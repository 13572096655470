import {
  ACTION_FETCH_USERS,
  ACTION_FETCH_USER_DETAILS,
  ACTION_UPDATE_USER,
  ACTION_SET_USERS,
  ACTION_DELETE_USER,
  ACTION_SET_USER_DETAILS,
  ACTION_SET_USER_DETAILS_IS_LOADED,
  ACTION_CREATE_USER,
  ACTION_FETCH_ROLES,
  ACTION_SET_ROLES,
  ACTION_FETCH_PERMISSIONS,
  ACTION_SET_PERMISSIONS,
} from './actionTypes';

export const fetchUsersEvent = () => ({
  type: ACTION_FETCH_USERS,
});

export const setUsersEvent = (users) => ({
  type: ACTION_SET_USERS,
  payload: { users },
});

export const fetchUserDetailsEvent = (userId) => ({
  type: ACTION_FETCH_USER_DETAILS,
  payload: { userId },
});

export const setUserDetailsEvent = (userData) => ({
  type: ACTION_SET_USER_DETAILS,
  payload: { userData },
});

export const setUserDetailsIsLoadedEvent = (isLoaded) => ({
  type: ACTION_SET_USER_DETAILS_IS_LOADED,
  payload: { isUserDetailsLoaded: isLoaded },
});

export const updateUserEvent = (userId, data) => ({
  type: ACTION_UPDATE_USER,
  payload: { userId, data },
});

export const createUserEvent = (data) => ({
  type: ACTION_CREATE_USER,
  payload: { data },
});

export const deleteUserEvent = (userId) => ({
  type: ACTION_DELETE_USER,
  payload: { userId },
});

export const fetchRolesEvent = () => ({
  type: ACTION_FETCH_ROLES,
});

export const setRolesEvent = (roles) => ({
  type: ACTION_SET_ROLES,
  payload: { roles },
});

export const fetchPermissionsEvent = () => ({
  type: ACTION_FETCH_PERMISSIONS,
});

export const setPermissionsEvent = (permissions) => ({
  type: ACTION_SET_PERMISSIONS,
  payload: { permissions },
});
