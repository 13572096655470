import React, {lazy} from 'react';
import {
  ROUTE_LEVEL_SECTIONS_CREATE_PAGE,
  ROUTE_LEVEL_SECTIONS_LIST_PAGE,
  ROUTE_LEVELS_CREATE_PAGE,
  ROUTE_LEVELS_EDIT_PAGE,
  ROUTE_LEVELS_LIST_PAGE,
} from '../../../../Ship/Constants/urls';

const LevelEditPage = lazy(() => import('../Components/Pages/LevelEditPage/LevelEditPage'));
const LevelsListPage = lazy(() => import('../Components/Pages/LevelsList/LevelsListPage'))
const SectionsEditPage = lazy(() => import('../Components/Pages/LevelSectionEditPage/LevelSectionEditPage'))
const SectionsListPage = lazy(() => import('../Components/Pages/LevelSectionsListPage/LevelSectionsListPage'))

const LevelRoutes = [
  {
    path: ROUTE_LEVELS_LIST_PAGE,
    element: <LevelsListPage/>,
  },
  {
    path: ROUTE_LEVELS_EDIT_PAGE,
    element: <LevelEditPage isEdit={true}/>,
  },
  {
    path: ROUTE_LEVELS_CREATE_PAGE,
    element: <LevelEditPage isEdit={false}/>,
  },
  {
    path: ROUTE_LEVEL_SECTIONS_LIST_PAGE,
    element: <SectionsListPage/>,
  },
  {
    path: ROUTE_LEVEL_SECTIONS_CREATE_PAGE,
    element: <SectionsEditPage isEdit={true}/>,
  },
];

export default LevelRoutes;
