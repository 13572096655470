export const ACTION_FETCH_GAMES = 'ACTION_FETCH_GAMES';
export const ACTION_SET_GAMES = 'ACTION_SET_GAMES';
export const ACTION_FETCH_GAME_DETAILS = 'ACTION_FETCH_GAME_DETAILS';
export const ACTION_SET_GAME_DETAILS = 'ACTION_SET_GAME_DETAILS';
export const ACTION_CREATE_GAME_TOKEN = 'ACTION_CREATE_GAME_TOKEN';
export const ACTION_SET_GAME_TOKEN = 'ACTION_SET_GAME_TOKEN';
export const ACTION_UPDATE_GAME = 'ACTION_UPDATE_GAME';
export const ACTION_CREATE_GAME = 'ACTION_CREATE_GAME';
export const ACTION_DELETE_GAME = 'ACTION_DELETE_GAME';
export const ACTION_GAME_UPDATED = 'ACTION_GAME_UPDATED';

export const ACTION_SET_GAME_DETAILS_IS_LOADED = 'ACTION_SET_GAME_DETAILS_IS_LOADED';
