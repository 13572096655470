export const levelTypes = {
  simple: {
    id: 'simple',
    name: 'Simple level',
  },
  complex: {
    id: 'complex',
    name: 'Complex level',
  },
  // reward: {
  //   id: 'reward',
  //   name: 'Reward'
  // },
}