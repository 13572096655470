import {
  ACTION_SET_PLAYER_DETAILS,
  ACTION_SET_PLAYERS,
  ACTION_SET_PLAYER_DETAILS_IS_LOADED,
} from './actionTypes';

const INIT_STATE = {
  players: [],
  playerDetails: {
    login: null,
  },
  moduleStates: {
    isPlayerDetailsLoaded: false,
  },
};

const PlayerManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_PLAYERS:
      return {
        ...state,
        players: action.payload.players,
      };

    case ACTION_SET_PLAYER_DETAILS:
      return {
        ...state,
        playerDetails: {
          login: action.payload.playerData.login,
        },
      };

    case ACTION_SET_PLAYER_DETAILS_IS_LOADED:
      return {
        ...state,
        moduleStates: {
          isPlayerDetailsLoaded: action.payload.isPlayerDetailsLoaded,
        },
      };

    default:
      return state;
  }
};

export default PlayerManagement;
