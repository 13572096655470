import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// users
import user1 from '../Assets/images/users/avatar-1.jpg';
import withRouter from '../../../../../Helpers/WithRouter';
import { setProfileEvent } from '../../../../../../Containers/ExternalSection/Auth/Store/Login/actions';

function ProfileMenu({ t }) {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state.Auth);

  useEffect(
    () => {
      if (
        localStorage.getItem('auth')
            && JSON.parse(localStorage.getItem('auth')).login
      ) {
        dispatch(setProfileEvent(JSON.parse(localStorage.getItem('auth'))));
      }
    },
    [dispatch],
  );

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={user1}
          alt="Header Avatar"
        />
        <span className="d-xl-inline-block ms-2 me-1">{profile.login}</span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem tag="a" href="/profile">
          {' '}
          <i className="bx bx-user font-size-16 align-middle me-1" />
          {t('Profile')}
          {' '}
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <i className="bx bx-wallet font-size-16 align-middle me-1" />
          {t('My Wallet')}
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <span className="badge bg-success float-end">11</span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          {t('Settings')}
        </DropdownItem>
        <DropdownItem tag="a" href="#">
          <i className="bx bx-lock-open font-size-16 align-middle me-1" />
          {t('Lock screen')}
        </DropdownItem>
        <div className="dropdown-divider" />
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
}

ProfileMenu.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStatetoProps = () => {
  const { error, success } = { error: false, success: true };
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
);
