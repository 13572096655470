import {
  ACTION_LOGIN_USER_SUCCESSFULLY,
  ACTION_REGISTER_USER_SUCCESSFULLY,
  ACTION_SET_PROFILE,
} from './actionTypes';
import User from '../../Models/User';

const INIT_STATE = {
  profile: {},
  error: {},
};

const Auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_LOGIN_USER_SUCCESSFULLY:
      return {
        ...state,
        profile: new User(
          {
            login: action.payload.user.login,
            status: action.payload.user.status,
            roles: action.payload.user.roles,
            permissions: action.payload.user.permissions,
          },
        ),
      };

    case ACTION_REGISTER_USER_SUCCESSFULLY:
      return {
        ...state,
        profile: new User(
          {
            login: action.payload.user.login,
            status: action.payload.user.status,
            roles: action.payload.user.roles,
            permissions: action.payload.user.permissions,
          },
        ),
      };

    case ACTION_SET_PROFILE:
      return {
        ...state,
        profile: new User(
          {
            login: action.payload.user.login,
            status: action.payload.user.status,
            roles: action.payload.user.roles,
            permissions: action.payload.user.permissions,
          },
        ),
      };

    default:
      return state;
  }
};

export default Auth;
