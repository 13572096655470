import { API_BASE_PREFIX } from '../../../Ship/Constants/configs';

export const API_FETCH_USERS = `${API_BASE_PREFIX}/users`;
export const API_DELETE_USER = `${API_BASE_PREFIX}/users/{user_id}`;
export const API_UPDATE_USER = `${API_BASE_PREFIX}/users/{user_id}`;
export const API_CREATE_USER = `${API_BASE_PREFIX}/users`;
export const API_FETCH_USER_DETAILS = `${API_BASE_PREFIX}/users/{user_id}`;

export const API_FETCH_ROLES = `${API_BASE_PREFIX}/roles`;

export const API_FETCH_PERMISSIONS = `${API_BASE_PREFIX}/permissions`;
