import React, { lazy } from 'react';
import {
  ROUTE_GAMES_PAGE,
  ROUTE_GAME_CONCERNS_PAGE,
} from '../../../../Ship/Constants/urls';

const GameManagementPage = lazy(() => import('../Components/Pages/GameListPage'));
const GameConcernsPage = lazy(() => import('../Components/Pages/GameConcernsPage'));

const GameRoutes = [
  {
    path: ROUTE_GAMES_PAGE,
    element: <GameManagementPage />,
  },
  {
    path: ROUTE_GAME_CONCERNS_PAGE,
    element: <GameConcernsPage />,
  },
];

export default GameRoutes;
