import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_CREATE_USER,
  ACTION_DELETE_USER,
  ACTION_FETCH_PERMISSIONS,
  ACTION_FETCH_ROLES,
  ACTION_FETCH_USER_DETAILS,
  ACTION_FETCH_USERS,
  ACTION_UPDATE_USER,
} from './actionTypes';
import ApiSenderFacade from '../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_DELETE_USER,
  API_FETCH_USER_DETAILS,
  API_FETCH_USERS,
  API_UPDATE_USER,
  API_CREATE_USER,
  API_FETCH_ROLES,
  API_FETCH_PERMISSIONS,
} from '../Constants/apiRoutes';
import {
  fetchUsersEvent, setPermissionsEvent, setRolesEvent,
  setUserDetailsEvent,
  setUserDetailsIsLoadedEvent,
  setUsersEvent,
} from './actions';
import notify from '../../../Ship/Helpers/Notification';

function* fetchUsersWorker() {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      () => apiHelper.get(API_FETCH_USERS),
    );

    yield put(setUsersEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching users:', error);
  }
}

function* deleteUserWorker({ payload: { userId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (user) => apiHelper
        .del(
          API_DELETE_USER.replace('{user_id}', user.id),
        ),
      {
        id: userId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The user was deleted, the list is being updating',
        },
      );

      yield put(fetchUsersEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while deleting the user.',
    });
  }
}

function* updateUserWorker({ payload: { userId, data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (user) => apiHelper
        .patch(
          API_UPDATE_USER.replace('{user_id}', user.id),
          user.data,
        ),
      {
        id: userId,
        data: {
          login: data.login,
          password: data.password,
          status: data.status,
          roles: data.roles.map((role) => role.id),
          permissions: data.permissions.map((permission) => permission.id),
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The user was updated, the list is being updating',
        },
      );

      yield put(fetchUsersEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the user.',
    });
  }
}

function* createUserWorker({ payload: { data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (user) => apiHelper
        .post(
          API_CREATE_USER,
          user.data,
        ),
      {
        data: {
          login: data.login,
          password: data.password,
          status: data.status,
          roles: data.roles.map((role) => role.id),
          permissions: data.permissions.map((permission) => permission.id),
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The user was created, the list is being updating',
        },
      );

      yield put(fetchUsersEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the user.',
    });
  }
}

function* fetchUserDetailsWorker({ payload: { userId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (user) => apiHelper
        .get(
          API_FETCH_USER_DETAILS.replace('{user_id}', user.id),
        ),
      {
        id: userId,
      },
    );

    yield put(setUserDetailsEvent(response.data));
    yield put(setUserDetailsIsLoadedEvent(true));
  } catch (error) {
    // console.error('An error occurred while fetching users:', error);
  }
}

function* fetchRolesWorker() {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      () => apiHelper
        .get(API_FETCH_ROLES),
    );

    yield put(setRolesEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching user roles:', error);
  }
}

function* fetchPermissionsWorker() {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      () => apiHelper
        .get(API_FETCH_PERMISSIONS),
    );

    yield put(setPermissionsEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching user permissions:', error);
  }
}

function* usersSagaWatcher() {
  yield takeEvery(ACTION_FETCH_USERS, fetchUsersWorker);
  yield takeEvery(ACTION_DELETE_USER, deleteUserWorker);
  yield takeEvery(ACTION_UPDATE_USER, updateUserWorker);
  yield takeEvery(ACTION_CREATE_USER, createUserWorker);
  yield takeEvery(ACTION_FETCH_USER_DETAILS, fetchUserDetailsWorker);
  yield takeEvery(ACTION_FETCH_ROLES, fetchRolesWorker);
  yield takeEvery(ACTION_FETCH_PERMISSIONS, fetchPermissionsWorker);
}

export default usersSagaWatcher;
