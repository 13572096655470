import { all, fork } from 'redux-saga/effects';

import AuthSaga from '../../Containers/ExternalSection/Auth/Store/Login/saga';

// GameManagementSection
import GameSaga from '../../Containers/GameManagementSection/Game/Store/saga';
import PlayerSaga from '../../Containers/GameManagementSection/Player/Store/saga';
import LayoutSaga from '../../Containers/GameManagementSection/Layout/Store/saga';
import SettingSaga from '../../Containers/GameManagementSection/Setting/Store/saga';
import LevelSaga from '../../Containers/GameManagementSection/Level/Store/saga';
import GameResourceSaga from '../../Containers/GameManagementSection/GameResource/Store/saga';

import UserSaga from '../../Containers/UserManagement/Store/saga';

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(GameSaga),
    fork(PlayerSaga),
    fork(UserSaga),
    fork(LayoutSaga),
    fork(SettingSaga),
    fork(LevelSaga),
    fork(GameResourceSaga),
  ]);
}
