import React, { lazy } from 'react';

const HomePage = lazy(() => import('../Components/Pages/HomePage'));

const homepageRoutes = [
  {
    path: '/',
    element: <HomePage />,
  },
];

export default homepageRoutes;
