import {
  ACTION_SET_GAME_RESOURCE_DETAILS, ACTION_SET_GAME_RESOURCE_MODULE_STATES,
  ACTION_SET_GAME_RESOURCES,
} from './actionTypes';
import RemoteFile from "../../../../Ship/Models/RemoteFile";

const INIT_STATE = {
  gameResources: [],
  gameResourceDetails: {
    tags: [],
    selectedFile: {},
    name,
  },
  moduleStates: {
    selectedGameResourceId: 0,
    isEditImageModalOpen: false,
    isDeleteConfirmationModalOpen: false,
  },
};

const GameResourceManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_GAME_RESOURCES:
      return {
        ...state,
        gameResources: action.payload.gameResources,
      };

    case ACTION_SET_GAME_RESOURCE_DETAILS:
      return {
        ...state,
        gameResourceDetails: {
          ...state.gameResourceDetails,
          ...action.payload.gameResourceData
        },
      };
      
    case ACTION_SET_GAME_RESOURCE_MODULE_STATES:
      return {
        ...state,
        moduleStates: {
          ...state.moduleStates,
          ...action.payload.moduleStates,
        }
      }

    default:
      return state;
  }
};

export default GameResourceManagement;
