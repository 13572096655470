import {call, put, takeEvery} from 'redux-saga/effects';
import {
  ACTION_CREATE_SETTING,
  ACTION_DELETE_SETTING,
  ACTION_FETCH_SETTING_DETAILS,
  ACTION_FETCH_SETTINGS,
  ACTION_UPDATE_SETTING,
} from './actionTypes';
import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_DELETE_SETTING,
  API_FETCH_SETTING_DETAILS,
  API_FETCH_SETTINGS,
  API_UPDATE_SETTING,
  API_CREATE_SETTING,
} from '../Constants/ApiRoutes';
import notify from '../../../../Ship/Helpers/Notification';
import {
  fetchSettingsEvent,
  setSettingDetailsEvent,
  setSettingDetailsIsLoadedEvent, setSettingErrorsEvent,
  setSettingsEvent,
} from './actions';

function* updateSettingWorker({payload: {settingId, data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    yield put(setSettingErrorsEvent([]));

    let errors = [];

    const response = yield call(
      (setting) => apiHelper
        .put(
          API_UPDATE_SETTING.replace('{setting_id}', setting.id),
          setting.data,
          {
            onError: (e) => {
              const errorMessage = e.response.data.errors.message[0];
              const settingId = e.response.data.errors.setting_id[0];

              notify(
                {
                  type: 'error',
                  title: 'Please, check the settings',
                  message: errorMessage,
                },
              );

              errors = [
                {
                  settingId,
                  errorMessage
                }
              ];
            }
          }
        ),
      {
        id: settingId,
        data: {
          name: data.name,
          schema: data.schema,
        },
      },
    );

    yield put(setSettingErrorsEvent(errors));

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The setting has been updated, the list is being updating',
        },
      );
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the setting.',
    });
  }
}

function* createSettingWorker({payload: {data}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    yield put(setSettingErrorsEvent([]));

    let errors = [];

    const response = yield call(
      (setting) => apiHelper
        .post(
          API_CREATE_SETTING,
          setting.data,
          {
            onError: (e) => {
              const errorMessage = e.response.data.errors.message[0];
              const settingId = e.response.data.errors.setting_id[0];

              notify(
                {
                  type: 'error',
                  title: 'Please, check the settings',
                  message: errorMessage,
                },
              );

              errors = [
                {
                  settingId,
                  errorMessage
                }
              ];
            }
          }
        ),
      {
        data: {
          name: data.name,
          schema: data.schema,
          game_uuid: data.game_uuid,
          layout_uuid: data.layout_uuid,
        },
      },
    );

    yield put(setSettingErrorsEvent(errors));

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The setting has been created, the list is being updating',
        },
      );
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the setting.',
    });
  }
}

function* fetchSettingDetailsWorker({payload: {settingId}}) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (setting) => apiHelper
        .get(
          API_FETCH_SETTING_DETAILS.replace('{setting_id}', setting.id),
        ),
      {
        id: settingId,
      },
    );

    yield put(setSettingDetailsEvent(response.data));
    yield put(setSettingDetailsIsLoadedEvent(true));
  } catch (error) {
    // console.error('An error occurred while fetching setting:', error);
  }
}

function* fetchSettingsWorker({payload: {gameId}}) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      () => apiHelper.get(`${API_FETCH_SETTINGS}?game_uuid=${gameId}`),
    );

    yield put(setSettingsEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching settings:', error);
  }
}

function* deleteSettingWorker({payload: {settingId}}) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (setting) => apiHelper
        .del(
          API_DELETE_SETTING.replace('{setting_id}', setting.id),
        ),
      {
        id: settingId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The setting has been deleted, the list is being updating',
        },
      );
      yield put(fetchSettingsEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while deleting the setting.',
    });
  }
}

function* settingsSagaWatcher() {
  yield takeEvery(ACTION_FETCH_SETTINGS, fetchSettingsWorker);
  yield takeEvery(ACTION_DELETE_SETTING, deleteSettingWorker);
  yield takeEvery(ACTION_UPDATE_SETTING, updateSettingWorker);
  yield takeEvery(ACTION_CREATE_SETTING, createSettingWorker);
  yield takeEvery(ACTION_FETCH_SETTING_DETAILS, fetchSettingDetailsWorker);
}

export default settingsSagaWatcher;
