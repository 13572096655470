import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import React from 'react';

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
};

export default withRouter;
