export const ACTION_FETCH_USERS = 'ACTION_FETCH_USERS';
export const ACTION_SET_USERS = 'ACTION_SET_USERS';
export const ACTION_FETCH_USER_DETAILS = 'ACTION_FETCH_USER_DETAILS';
export const ACTION_SET_USER_DETAILS = 'ACTION_SET_USER_DETAILS';
export const ACTION_UPDATE_USER = 'ACTION_UPDATE_USER';
export const ACTION_CREATE_USER = 'ACTION_CREATE_USER';
export const ACTION_DELETE_USER = 'ACTION_DELETE_USER';

export const ACTION_SET_USER_DETAILS_IS_LOADED = 'ACTION_SET_USER_DETAILS_IS_LOADED';

export const ACTION_FETCH_ROLES = 'ACTION_FETCH_ROLES';
export const ACTION_SET_ROLES = 'ACTION_SET_ROLES';
export const ACTION_FETCH_PERMISSIONS = 'ACTION_FETCH_PERMISSIONS';
export const ACTION_SET_PERMISSIONS = 'ACTION_SET_PERMISSIONS';
