import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';

import { connect } from 'react-redux';

// SimpleBar
import SimpleBar from 'simplebar-react';

import { Link } from 'react-router-dom';
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changePreloader,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
} from '../Store/layout/actions';

import '../Assets/scss/rightbar.scss';

// Import images
import bgimg1 from '../Assets/images/sidebar/img1.jpg';
import bgimg2 from '../Assets/images/sidebar/img2.jpg';
import bgimg4 from '../Assets/images/sidebar/img4.jpg';
import layout1 from '../Assets/images/layouts/layout-1.jpg';
import layout2 from '../Assets/images/layouts/layout-2.jpg';
import layout3 from '../Assets/images/layouts/layout-3.jpg';

// constants
import {
  layoutModeTypes,
  layoutTypes,
  layoutWidthTypes,
  leftBarThemeImageTypes,
  leftSideBarThemeTypes,
  leftSidebarTypes,
  topBarThemeTypes,
} from '../Constants/layout';

function RightSidebar({
  isPreloader,
  layoutType,
  layoutModeType,
  layoutWidth,
  leftSideBarTheme,
  leftSideBarThemeImage,
  leftSideBarType,
  topbarTheme,
}) {
  return (
    <>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: '900px' }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="/#"
                onClick={(e) => {
                  e.preventDefault();
                  showRightSidebarAction(false);
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Settings</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts</span>
                <label className="me-1" htmlFor="radioVertical">
                  Vertical
                  <input
                    type="radio"
                    id="radioVertical"
                    name="radioFruit"
                    value={layoutTypes.VERTICAL}
                    checked={layoutType === layoutTypes.VERTICAL}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayout(e.target.value);
                      }
                    }}
                  />
                </label>
                <label htmlFor="radioHorizontal">
                  Horizontal
                  <input
                    type="radio"
                    id="radioHorizontal"
                    name="radioFruit"
                    value={layoutTypes.HORIZONTAL}
                    checked={layoutType === layoutTypes.HORIZONTAL}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayout(e.target.value);
                      }
                    }}
                  />
                </label>
              </div>
              <hr className="mt-1" />
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Layouts Mode</span>
                <label className="me-1" htmlFor="radioLight">
                  Light
                  <input
                    type="radio"
                    id="radioLight"
                    name="radioLight"
                    value={layoutModeTypes.LIGHT}
                    checked={layoutModeType === layoutModeTypes.LIGHT}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayoutMode(e.target.value);
                      }
                    }}
                  />
                </label>
                <label htmlFor="radioDark">
                  Dark
                  <input
                    type="radio"
                    id="radioDark"
                    name="radioDark"
                    value={layoutModeTypes.DARK}
                    checked={layoutModeType === layoutModeTypes.DARK}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayoutMode(e.target.value);
                      }
                    }}
                  />
                </label>
              </div>

              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Layout Width
                </span>
                <label className="me-1" htmlFor="radioFluid">
                  Fluid
                  <input
                    type="radio"
                    id="radioFluid"
                    name="radioWidth"
                    value={layoutWidthTypes.FLUID}
                    checked={layoutWidth === layoutWidthTypes.FLUID}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayoutWidth(e.target.value);
                      }
                    }}
                  />
                </label>
                <label htmlFor="radioBoxed" className="me-1">
                  Boxed
                  <input
                    type="radio"
                    id="radioBoxed"
                    name="radioWidth"
                    value={layoutWidthTypes.BOXED}
                    checked={layoutWidth === layoutWidthTypes.BOXED}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayoutWidth(e.target.value);
                      }
                    }}
                  />
                </label>
                <label htmlFor="radioscrollable">
                  Scrollable
                  <input
                    type="radio"
                    id="radioscrollable"
                    name="radioscrollable"
                    value={layoutWidthTypes.SCROLLABLE}
                    checked={layoutWidth === layoutWidthTypes.SCROLLABLE}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeLayoutWidth(e.target.value);
                      }
                    }}
                  />
                </label>
              </div>
              <hr className="mt-1" />

              <div className="radio-toolbar">
                <span className="mb-2 d-block" id="radio-title">
                  Topbar Theme
                </span>
                <label className="me-1" htmlFor="radioThemeLight">
                  Light
                  <input
                    type="radio"
                    id="radioThemeLight"
                    name="radioTheme"
                    value={topBarThemeTypes.LIGHT}
                    checked={topbarTheme === topBarThemeTypes.LIGHT}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeTopbarTheme(e.target.value);
                      }
                    }}
                  />
                </label>

                <label className="me-1" htmlFor="radioThemeDark">
                  Dark
                  <input
                    type="radio"
                    id="radioThemeDark"
                    name="radioTheme"
                    value={topBarThemeTypes.DARK}
                    checked={topbarTheme === topBarThemeTypes.DARK}
                    onChange={(e) => {
                      if (e.target.checked) {
                        changeTopbarTheme(e.target.value);
                      }
                    }}
                  />
                </label>
                {layoutType === 'vertical' ? null : (
                  <>
                    <label className="me-1" htmlFor="radioThemeColored">
                      Colored
                      <input
                        type="radio"
                        id="radioThemeColored"
                        name="radioTheme"
                        value={topBarThemeTypes.COLORED}
                        checked={topbarTheme === topBarThemeTypes.COLORED}
                        onChange={(e) => {
                          if (e.target.checked) {
                            changeTopbarTheme(e.target.value);
                          }
                        }}
                      />
                    </label>
                    {' '}
                  </>
                )}
              </div>

              {layoutType === 'vertical' ? (
                <>
                  <hr className="mt-1" />
                  <div className="radio-toolbar">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Type
                      {' '}
                    </span>

                    <label className="me-1" htmlFor="sidebarDefault">
                      Default
                      <input
                        type="radio"
                        id="sidebarDefault"
                        name="sidebarType"
                        value={leftSidebarTypes.DEFAULT}
                        checked={leftSideBarType === leftSidebarTypes.DEFAULT}
                        onChange={(e) => {
                          if (e.target.checked) {
                            changeSidebarType(e.target.value);
                          }
                        }}
                      />
                    </label>

                    <label className="me-1" htmlFor="sidebarCompact">
                      Compact
                      <input
                        type="radio"
                        id="sidebarCompact"
                        name="sidebarType"
                        value={leftSidebarTypes.COMPACT}
                        checked={leftSideBarType === leftSidebarTypes.COMPACT}
                        onChange={(e) => {
                          if (e.target.checked) {
                            changeSidebarType(e.target.value);
                          }
                        }}
                      />
                    </label>

                    <label className="me-1" htmlFor="sidebarIcon">
                      Icon
                      <input
                        type="radio"
                        id="sidebarIcon"
                        name="sidebarType"
                        value={leftSidebarTypes.ICON}
                        checked={leftSideBarType === leftSidebarTypes.ICON}
                        onChange={(e) => {
                          if (e.target.checked) {
                            changeSidebarType(e.target.value);
                          }
                        }}
                      />
                    </label>
                  </div>

                  <hr className="mt-1" />

                  <div className="radio-toolbar coloropt-radio">
                    <span className="mb-2 d-block" id="radio-title">
                      Left Sidebar Color Options
                    </span>
                    <Row>
                      <Col>
                        <label
                          htmlFor="leftsidebarThemelight"
                          className="bg-light rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemelight"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.LIGHT}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.LIGHT}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemedark"
                          className="bg-dark rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemedark"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.DARK}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.DARK}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemecolored"
                          className="bg-colored rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemecolored"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.COLORED}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.COLORED}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label
                          htmlFor="leftsidebarThemewinter"
                          className="gradient-winter rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemewinter"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.WINTER}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.WINTER}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemeladylip"
                          className="gradient-lady-lip rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemeladylip"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.LADYLIP}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.LADYLIP}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemeplumplate"
                          className="gradient-plum-plate rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemeplumplate"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.PLUMPLATE}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.PLUMPLATE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemestrongbliss"
                          className="gradient-strong-bliss rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemestrongbliss"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.STRONGBLISS}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.STRONGBLISS}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                        <label
                          htmlFor="leftsidebarThemesgreatwhale"
                          className="gradient-strong-great-whale rounded-circle wh-30 me-1"
                        >
                          <input
                            type="radio"
                            id="leftsidebarThemesgreatwhale"
                            name="leftsidebarTheme"
                            value={leftSideBarThemeTypes.GREATWHALE}
                            checked={leftSideBarTheme === leftSideBarThemeTypes.GREATWHALE}
                            onChange={(e) => {
                              if (e.target.checked) {
                                changeSidebarTheme(e.target.value);
                              }
                            }}
                          />
                        </label>
                      </Col>
                    </Row>
                  </div>
                  <div className="radio-toolbar imgopt-radio">
                    <span className="mb-2 d-block" id="radio-bgimg">
                      Left Sidebar Bg Image
                    </span>
                    <div className="d-flex gap-2 flex-wrap">
                      <label htmlFor="leftsidebarThemebgimg1">
                        <img
                          alt=""
                          width="90"
                          className="themesideimage rounded"
                          src={bgimg1}
                        />
                        <input
                          type="radio"
                          id="leftsidebarThemebgimg1"
                          name="leftsidebarThemeImage"
                          value={leftBarThemeImageTypes.IMG1}
                          checked={leftSideBarThemeImage === leftBarThemeImageTypes.IMG1}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeSidebarThemeImage(e.target.value);
                            }
                          }}
                        />
                      </label>
                      {'   '}
                      <label htmlFor="leftsidebarThemebgimg2">
                        <img
                          alt=""
                          width="90"
                          className="themesideimage rounded"
                          src={bgimg2}
                        />
                        <input
                          type="radio"
                          id="leftsidebarThemebgimg2"
                          name="leftsidebarThemeImage"
                          value={leftBarThemeImageTypes.IMG2}
                          checked={leftSideBarThemeImage === leftBarThemeImageTypes.IMG2}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeSidebarThemeImage(e.target.value);
                            }
                          }}
                        />
                      </label>
                      {'   '}
                      <label htmlFor="leftsidebarThemebgimg3">
                        <img
                          alt=""
                          width="90"
                          className="themesideimage rounded"
                          src="something"
                        />
                        <input
                          type="radio"
                          id="leftsidebarThemebgimg3"
                          name="leftsidebarThemeImage"
                          value={leftBarThemeImageTypes.IMG3}
                          checked={leftSideBarThemeImage === leftBarThemeImageTypes.IMG3}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeSidebarThemeImage(e.target.value);
                            }
                          }}
                        />
                      </label>
                      {'   '}
                      <label htmlFor="leftsidebarThemebgimg4">
                        <img
                          alt=""
                          width="90"
                          className="themesideimage rounded"
                          src={bgimg4}
                        />
                        <input
                          type="radio"
                          id="leftsidebarThemebgimg4"
                          name="leftsidebarThemeImage"
                          value={leftBarThemeImageTypes.IMG4}
                          checked={leftSideBarThemeImage === leftBarThemeImageTypes.IMG4}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeSidebarThemeImage(e.target.value);
                            }
                          }}
                        />
                      </label>
                      {'   '}
                      <label htmlFor="leftsidebarThemenone">
                        <div style={{ width: '40px', height: '80px' }}>
                          <div className="bg-light border px-2 h-100 shadow-none">
                            <div className="verticalcontent">None</div>
                          </div>
                        </div>
                        <input
                          type="radio"
                          id="leftsidebarThemenone"
                          name="leftsidebarThemeImage"
                          value={leftBarThemeImageTypes.NONE}
                          checked={leftSideBarThemeImage === leftBarThemeImageTypes.NONE}
                          onChange={(e) => {
                            if (e.target.checked) {
                              changeSidebarThemeImage(e.target.value);
                            }
                          }}
                        />
                      </label>
                      {'   '}
                    </div>
                  </div>
                  <hr className="mt-1" />
                </>
              ) : null}

              <FormGroup>
                <span className="mb-2 d-block" id="radio-title">
                  Preloader
                </span>

                <div className="form-check form-switch">
                  <label className="form-check-label" htmlFor="checkbox_1">
                    Preloader
                    <input
                      type="checkbox"
                      className="form-check-input checkbox"
                      id="checkbox_1"
                      checked={isPreloader}
                      onChange={() => {
                        changePreloader(!isPreloader);
                      }}
                    />
                  </label>
                </div>
              </FormGroup>

              <h6 className="text-center">Choose Layouts</h6>

              <div className="mb-2">
                <Link to="//skote-v-light.react.themesbrand.com" target="_blank">
                  <img src={layout1} className="img-fluid img-thumbnail" alt="" />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//skote-v-dark.react.themesbrand.com" target="_blank">
                  <img src={layout2} className="img-fluid img-thumbnail" alt="" />
                </Link>
              </div>

              <div className="mb-2">
                <Link to="//skote-v-rtl.react.themesbrand.com" target="_blank">
                  <img src={layout3} className="img-fluid img-thumbnail" alt="" />
                </Link>
              </div>

              <Link
                to="//1.envato.market/skotereact"
                className="btn btn-primary btn-block mt-3"
                target="_blank"
              >
                <i className="mdi mdi-cart ms-1" />
                {' '}
                Purchase Now
              </Link>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay" />
    </>
  );
}

RightSidebar.propTypes = {
  isPreloader: PropTypes.node.isRequired,
  layoutType: PropTypes.node.isRequired,
  layoutModeType: PropTypes.node.isRequired,
  layoutWidth: PropTypes.node.isRequired,
  leftSideBarTheme: PropTypes.node.isRequired,
  leftSideBarThemeImage: PropTypes.node.isRequired,
  leftSideBarType: PropTypes.node.isRequired,
  topbarTheme: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({ ...state.Layout });

export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
