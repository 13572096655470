import React from 'react';
import { Provider } from 'react-redux';
import store from './Ship/Store';
import RootRoutes from './Ship/Routes/Routes';
// @todo(mt): add configuration for theme selection
import './Ship/Themes/Skote/assets/scss/app.scss';
import './Ship/Themes/Skote/assets/scss/theme.scss';
import './App.scss';

function App() {
  return (
    <Provider store={store}>
      <RootRoutes />
    </Provider>
  );
}

export default App;
