import { takeEvery, put, call } from 'redux-saga/effects';

import {
  ACTION_LOGIN_USER,
  ACTION_LOGIN_USER_SUCCESSFULLY,
  ACTION_REGISTER_USER,
  ACTION_REGISTER_USER_SUCCESSFULLY,
} from './actionTypes';
import { apiError, loginUserSuccessfully, registerUserSuccessfully } from './actions';
import ApiSenderFacade from '../../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import { POST_AUTH_ATTEMPT_API, POST_REGISTER_API } from '../../Constants/apiRoutes';
import notify from '../../../../../Ship/Helpers/Notification';

function* loginUserWorker({ payload: { user, navigate } }) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (credentials) => apiHelper.post(POST_AUTH_ATTEMPT_API, credentials),
      {
        login: user.login,
        password: user.password,
      },
    );

    yield put(loginUserSuccessfully(response.data, navigate));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerUserWorker({ payload: { user, navigate } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (credentials) => apiHelper.post(POST_REGISTER_API, credentials),
      {
        login: user.login,
        password: user.password,
      },
    );
    yield put(registerUserSuccessfully(response.data, navigate));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* userSignedInSuccessfullyWorker({ payload: { user, navigate } }) {
  yield notify(
    {
      type: 'success',
      title: 'Signed in',
      message: 'You have signed in successfully',
    },
  );
  const DASHBOARD_PATH = '/dashboard';
  localStorage.setItem(
    'auth',
    JSON.stringify(
      {
        isSignedIn: true,
        login: user.login,
        status: user.status,
        roles: user.roles,
        permissions: user.permissions,
        id: user.uuid,
      },
    ),
  );

  navigate(DASHBOARD_PATH);
}

function* userRegisteredSuccessfullyWorker({ payload: { user, navigate } }) {
  yield notify(
    {
      type: 'success',
      title: 'Registered',
      message: 'You have registered successfully',
    },
  );
  const DASHBOARD_PATH = '/dashboard';
  localStorage.setItem(
    'auth',
    JSON.stringify(
      {
        isSignedIn: true,
        login: user.login,
        status: user.status,
        roles: user.roles,
        permissions: user.permissions,
        id: user.uuid,
      },
    ),
  );

  navigate(DASHBOARD_PATH);
}

function* authSagaWatcher() {
  yield takeEvery(ACTION_LOGIN_USER, loginUserWorker);
  yield takeEvery(ACTION_REGISTER_USER, registerUserWorker);
  yield takeEvery(ACTION_LOGIN_USER_SUCCESSFULLY, userSignedInSuccessfullyWorker);
  yield takeEvery(ACTION_REGISTER_USER_SUCCESSFULLY, userRegisteredSuccessfullyWorker);
}

export default authSagaWatcher;
