import React, { lazy } from 'react';
import {ROUTE_LOGIN_PAGE} from "../../../../Ship/Constants/urls";

const LoginPage = lazy(() => import('../Components/Pages/LoginPage'));
const RegistrationPage = lazy(() => import('../Components/Pages/RegistrationPage'));

const authRoutes = [
  {
    path: ROUTE_LOGIN_PAGE,
    element: <LoginPage />,
  },
  {
    path: '/registration',
    element: <RegistrationPage />,
  },
];
export default authRoutes;
