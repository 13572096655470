import {
  ACTION_SET_LAYOUT_DETAILS,
  ACTION_SET_LAYOUT_DETAILS_IS_LOADED,
  ACTION_SET_LAYOUTS,
} from './actionTypes';
import {LAYOUT_DEFAULT_SCHEMA} from "../Constants/DefaultSchema";

const INIT_STATE = {
  layouts: [],
  layoutDetails: {
    schema: LAYOUT_DEFAULT_SCHEMA,
    name: '',
    version: '',
  },
  moduleStates: {
    isLayoutDetailsLoaded: false,
  },
};

const LayoutManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_LAYOUTS:
      return {
        ...state,
        layouts: action.payload.layouts,
      };

    case ACTION_SET_LAYOUT_DETAILS:
      return {
        ...state,
        layoutDetails: {
          name: action.payload.data.name,
          schema: action.payload.data.schema,
          version: action.payload.data.version,
        },
      };

    case ACTION_SET_LAYOUT_DETAILS_IS_LOADED:
      return {
        ...state,
        moduleStates: {
          isLayoutDetailsLoaded: action.payload.isLayoutDetailsLoaded,
        },
      };

    default:
      return state;
  }
};

export default LayoutManagement;
