import {
  ACTION_FETCH_GAMES,
  ACTION_FETCH_GAME_DETAILS,
  ACTION_UPDATE_GAME,
  ACTION_SET_GAMES,
  ACTION_DELETE_GAME,
  ACTION_CREATE_GAME_TOKEN,
  ACTION_SET_GAME_TOKEN,
  ACTION_SET_GAME_DETAILS,
  ACTION_SET_GAME_DETAILS_IS_LOADED,
  ACTION_CREATE_GAME,
} from './actionTypes';

export const fetchGamesEvent = () => ({
  type: ACTION_FETCH_GAMES,
});

export const setGamesEvent = (games) => ({
  type: ACTION_SET_GAMES,
  payload: { games },
});

export const fetchGameDetailsEvent = (gameId) => ({
  type: ACTION_FETCH_GAME_DETAILS,
  payload: { gameId },
});

export const setGameDetailsEvent = (gameData) => ({
  type: ACTION_SET_GAME_DETAILS,
  payload: { gameData },
});

export const setGameDetailsIsLoadedEvent = (isLoaded) => ({
  type: ACTION_SET_GAME_DETAILS_IS_LOADED,
  payload: { isGameDetailsLoaded: isLoaded },
});

export const createGameTokenEvent = (gameId) => ({
  type: ACTION_CREATE_GAME_TOKEN,
  payload: { gameId },
});

export const setGameTokenDataEvent = (gameToken, isGameTokenCreated) => ({
  type: ACTION_SET_GAME_TOKEN,
  payload: { gameToken, isGameTokenCreated },
});

export const updateGameEvent = (gameId, data) => ({
  type: ACTION_UPDATE_GAME,
  payload: { gameId, data },
});

export const createGameEvent = (data) => ({
  type: ACTION_CREATE_GAME,
  payload: { data },
});

export const deleteGameEvent = (gameId, gameToken) => ({
  type: ACTION_DELETE_GAME,
  payload: { gameId, gameToken },
});
