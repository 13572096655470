import {
  ACTION_FETCH_PLAYERS,
  ACTION_FETCH_PLAYER_DETAILS,
  ACTION_UPDATE_PLAYER,
  ACTION_SET_PLAYERS,
  ACTION_SET_PLAYER_DETAILS,
  ACTION_SET_PLAYER_DETAILS_IS_LOADED,
  ACTION_CREATE_PLAYER,
} from './actionTypes';

export const fetchPlayersEvent = (gameId) => ({
  type: ACTION_FETCH_PLAYERS,
  payload: { gameId },
});

export const setPlayersEvent = (players) => ({
  type: ACTION_SET_PLAYERS,
  payload: { players },
});

export const fetchPlayerDetailsEvent = (playerId) => ({
  type: ACTION_FETCH_PLAYER_DETAILS,
  payload: { playerId },
});

export const setPlayerDetailsEvent = (playerData) => ({
  type: ACTION_SET_PLAYER_DETAILS,
  payload: { playerData },
});

export const setPlayerDetailsIsLoadedEvent = (isLoaded) => ({
  type: ACTION_SET_PLAYER_DETAILS_IS_LOADED,
  payload: { isPlayerDetailsLoaded: isLoaded },
});

export const updatePlayerEvent = (playerId, data) => ({
  type: ACTION_UPDATE_PLAYER,
  payload: { playerId, data },
});

export const createPlayerEvent = (data) => ({
  type: ACTION_CREATE_PLAYER,
  payload: { data },
});
