import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

function LoadingWrapper({ children }) {
  const { isLoading } = useSelector((state) => state.System);

  return isLoading
    ? (
      <>
        {children}
        <div
          style={
                    {
                      background: 'rgba(10,10,10,0.5)',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: '99999',
                    }
                }
        >
          <div
            style={
                        {
                          position: 'absolute',
                          top: '30%',
                          left: '50%',
                          translateY: '-50%',
                          translateX: '-50%',
                        }
                    }
          >
            <i
              style={
                            {
                              fontSize: '100px',
                              animationDuration: '900ms',
                            }
                        }
              className="bx bx-loader-alt bx-spin"
            />
            <p style={{ fontSize: '24px' }}>Loading</p>
          </div>
        </div>
      </>
    )
    : children;
}

LoadingWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
export default LoadingWrapper;
