import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_CREATE_LAYOUT,
  ACTION_DELETE_LAYOUT,
  ACTION_FETCH_LAYOUT_DETAILS,
  ACTION_FETCH_LAYOUTS,
  ACTION_UPDATE_LAYOUT,
} from './actionTypes';
import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_DELETE_LAYOUT,
  API_FETCH_LAYOUT_DETAILS,
  API_FETCH_LAYOUTS,
  API_UPDATE_LAYOUT,
  API_CREATE_LAYOUT,
} from '../Constants/ApiRoutes';

import notify from '../../../../Ship/Helpers/Notification';
import {
  fetchLayoutsEvent,
  setLayoutDetailsEvent,
  setLayoutDetailsIsLoadedEvent,
  setLayoutsEvent,
} from './actions';

function* updateLayoutWorker({ payload: { layoutId, data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (layout) => apiHelper
        .put(
          API_UPDATE_LAYOUT.replace('{layout_id}', layout.id),
          layout.data,
        ),
      {
        id: layoutId,
        data: {
          name: data.name,
          schema: data.schema,
          version: data.version,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The layout has been updated, the list is being updating',
        },
      );
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the layout.',
    });
  }
}

function* createLayoutWorker({ payload: { data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (layout) => apiHelper
        .post(
          API_CREATE_LAYOUT,
          layout.data,
        ),
      {
        data: {
          name: data.name,
          schema: data.schema,
          version: data.version,
          game_uuid: data.game_id,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The layout has been created, the list is being updating',
        },
      );
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the layout.',
    });
  }
}

function* fetchLayoutDetailsWorker({ payload: { layoutId } }) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (layout) => apiHelper
        .get(
          API_FETCH_LAYOUT_DETAILS.replace('{layout_id}', layout.id),
        ),
      {
        id: layoutId,
      },
    );

    yield put(setLayoutDetailsEvent(response.data));
    yield put(setLayoutDetailsIsLoadedEvent(true));
  } catch (error) {
    // console.error('An error occurred while fetching layout:', error);
  }
}

function* fetchLayoutsWorker({ payload: { gameId } }) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      () => apiHelper.get(`${API_FETCH_LAYOUTS}?game_uuid=${gameId}`),
    );

    yield put(setLayoutsEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching layouts:', error);
  }
}

function* deleteLayoutWorker({ payload: { layoutId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (layout) => apiHelper
        .del(
          API_DELETE_LAYOUT.replace('{layout_id}', layout.id),
        ),
      {
        id: layoutId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The layout has been deleted, the list is being updating',
        },
      );
      yield put(fetchLayoutsEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while deleting the layout.',
    });
  }
}

function* layoutsSagaWatcher() {
  yield takeEvery(ACTION_FETCH_LAYOUTS, fetchLayoutsWorker);
  yield takeEvery(ACTION_DELETE_LAYOUT, deleteLayoutWorker);
  yield takeEvery(ACTION_UPDATE_LAYOUT, updateLayoutWorker);
  yield takeEvery(ACTION_CREATE_LAYOUT, createLayoutWorker);
  yield takeEvery(ACTION_FETCH_LAYOUT_DETAILS, fetchLayoutDetailsWorker);
}

export default layoutsSagaWatcher;
