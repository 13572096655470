import { call, put, takeEvery } from 'redux-saga/effects';

import {
  ACTION_CREATE_GAME,
  ACTION_CREATE_GAME_TOKEN,
  ACTION_DELETE_GAME,
  ACTION_FETCH_GAME_DETAILS,
  ACTION_FETCH_GAMES,
  ACTION_UPDATE_GAME,
} from './actionTypes';
import ApiSenderFacade from '../../../../Ship/Libs/ApiSender/ApiSenderFacade';
import {
  API_CREATE_GAME_TOKEN,
  API_DELETE_GAME,
  API_FETCH_GAME_DETAILS,
  API_FETCH_GAMES,
  API_UPDATE_GAME,
  API_CREATE_GAME,
} from '../Constants/ApiRoutes';
import {
  fetchGamesEvent,
  setGameDetailsEvent,
  setGameDetailsIsLoadedEvent,
  setGamesEvent,
  setGameTokenDataEvent,
} from './actions';
import notify from '../../../../Ship/Helpers/Notification';

function* fetchGamesWorker() {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      () => apiHelper.get(API_FETCH_GAMES),
    );

    yield put(setGamesEvent(response.data));
  } catch (error) {
    // console.error('An error occurred while fetching games:', error);
  }
}

function* deleteGameWorker({ payload: { gameId, gameToken } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (game) => apiHelper
        .del(
          API_DELETE_GAME.replace('{game_uuid}', game.id),
          {
            headers: {
              'X-GameToken': gameToken,
            },
          },
        ),
      {
        id: gameId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game was deleted, the list is being updating',
        },
      );

      yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while deleting the game.',
    });
  }
}

function* createGameTokenWorker({ payload: { gameId } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (game) => apiHelper
        .post(
          API_CREATE_GAME_TOKEN.replace('{game_uuid}', game.id),
        ),
      {
        id: gameId,
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game token has been successfully rewritten',
        },
      );

      yield put(fetchGamesEvent());
    }
    yield put(setGameTokenDataEvent(response.data.api_token, true));
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the game token.',
    });
  }
}

function* updateGameWorker({ payload: { gameId, data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (game) => apiHelper
        .put(
          API_UPDATE_GAME.replace('{game_uuid}', game.id),
          game.data,
        ),
      {
        id: gameId,
        data: {
          name: data.name,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game was updated, the list is being updating',
        },
      );

      yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while updating the game.',
    });
  }
}

function* createGameWorker({ payload: { data } }) {
  const apiHelper = ApiSenderFacade.getInstance();

  try {
    const response = yield call(
      (game) => apiHelper
        .post(
          API_CREATE_GAME,
          game.data,
        ),
      {
        data: {
          name: data.name,
          genre: data.genre,
        },
      },
    );

    if (response !== undefined) {
      notify(
        {
          type: 'success',
          title: 'Success',
          message: 'The game was created, the list is being updating',
        },
      );

      yield put(fetchGamesEvent());
    }
  } catch (error) {
    notify({
      type: 'error',
      title: 'Error',
      message: 'An error occurred while creating the game.',
    });
  }
}

function* fetchGameDetailsWorker({ payload: { gameId } }) {
  const apiHelper = ApiSenderFacade.getInstance();
  try {
    const response = yield call(
      (game) => apiHelper
        .get(
          API_FETCH_GAME_DETAILS.replace('{game_uuid}', game.id),
        ),
      {
        id: gameId,
      },
    );
    yield put(setGameDetailsEvent(response.data));
    yield put(setGameDetailsIsLoadedEvent(true));
  } catch (error) {
    // console.error('An error occurred while fetching game:', error);
  }
}

function* gamesSagaWatcher() {
  yield takeEvery(ACTION_FETCH_GAMES, fetchGamesWorker);
  yield takeEvery(ACTION_DELETE_GAME, deleteGameWorker);
  yield takeEvery(ACTION_CREATE_GAME_TOKEN, createGameTokenWorker);
  yield takeEvery(ACTION_UPDATE_GAME, updateGameWorker);
  yield takeEvery(ACTION_CREATE_GAME, createGameWorker);
  yield takeEvery(ACTION_FETCH_GAME_DETAILS, fetchGameDetailsWorker);
}

export default gamesSagaWatcher;
