import usFlag from '../images/flags/us.jpg';
import spain from '../images/flags/spain.jpg';
import germany from '../images/flags/germany.jpg';
import italy from '../images/flags/italy.jpg';
import russia from '../images/flags/russia.jpg';

const languages = {
  sp: {
    label: 'Spanish',
    flag: spain,
  },
  gr: {
    label: 'German',
    flag: germany,
  },
  it: {
    label: 'Italian',
    flag: italy,
  },
  rs: {
    label: 'Russian',
    flag: russia,
  },
  en: {
    label: 'English',
    flag: usFlag,
  },
};

export default languages;
