class GameResource {
  id = 0;
  name = '';
  tags = [];
  link = '';
  hash = '';
  
  constructor({id, name, tags, link, hash, settings}) {
    this.id = id;
    this.name = name;
    this.tags = tags;
    this.link = link;
    this.hash = hash;
    this.settings = settings;
  }
  
  static buildFrom(data) {
    const {id, name, tags, link, hash, settings} = data;
    
    return new GameResource({id, name, tags, link, hash, settings});
  }
}

export default GameResource;