import React, { lazy } from 'react';
import {
  ROUTE_LAYOUTS_CREATE_PAGE,
  ROUTE_LAYOUTS_EDIT_PAGE,
} from '../../../../Ship/Constants/urls';

const LayoutCreateEditPage = lazy(() => import('../Components/Pages/LayoutCreateEditPage/LayoutCreateEditPage'));

const LayoutRoutes = [
  {
    path: ROUTE_LAYOUTS_CREATE_PAGE,
    element: <LayoutCreateEditPage isEditMode={false} />,
  },
  {
    path: ROUTE_LAYOUTS_EDIT_PAGE,
    element: <LayoutCreateEditPage isEditMode={true} />,
  },
];

export default LayoutRoutes;
