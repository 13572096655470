export const ACTION_FETCH_LEVELS = 'ACTION_FETCH_LEVELS';
export const ACTION_SET_LEVELS = 'ACTION_SET_LEVELS';
export const ACTION_SET_LEVEL_MODULE_STATES = 'ACTION_SET_LEVEL_MODULE_STATES';
export const ACTION_FETCH_LEVEL_DETAILS = 'ACTION_FETCH_LEVEL_DETAILS';
export const ACTION_SET_LEVEL_DETAILS = 'ACTION_SET_LEVEL_DETAILS';
export const ACTION_UPDATE_LEVEL = 'ACTION_UPDATE_LEVEL';
export const ACTION_PUBLISH_LEVEL = 'ACTION_PUBLISH_LEVEL';
export const ACTION_CREATE_LEVEL = 'ACTION_CREATE_LEVEL';
export const ACTION_DELETE_LEVEL = 'ACTION_DELETE_LEVEL';


export const ACTION_SET_LEVEL_SECTION_DETAILS = 'ACTION_SET_LEVEL_SECTION_DETAILS';
export const ACTION_UPDATE_LEVEL_SECTION = 'ACTION_UPDATE_LEVEL_SECTION';
export const ACTION_PUBLISH_LEVEL_SECTION = 'ACTION_PUBLISH_LEVEL_SECTION';
export const ACTION_CREATE_LEVEL_SECTION = 'ACTION_CREATE_LEVEL_SECTION';
