import {
  ACTION_SET_USER_DETAILS,
  ACTION_SET_USERS,
  ACTION_SET_USER_DETAILS_IS_LOADED, ACTION_SET_ROLES, ACTION_SET_PERMISSIONS,
} from './actionTypes';

const INIT_STATE = {
  users: [],
  userDetails: {
    login: null,
    role: null,
    status: null,
  },
  moduleStates: {
    isUserDetailsLoaded: false,
  },
  roles: [],
  permissions: [],
};

const UserManagement = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTION_SET_USERS:
      return {
        ...state,
        users: action.payload.users,
      };

    case ACTION_SET_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          login: action.payload.userData.login,
          roles: action.payload.userData.roles,
          permissions: action.payload.userData.permissions,
          status: action.payload.userData.status,
        },
      };

    case ACTION_SET_USER_DETAILS_IS_LOADED:
      return {
        ...state,
        moduleStates: {
          isUserDetailsLoaded: action.payload.isUserDetailsLoaded,
        },
      };

    case ACTION_SET_ROLES:
      return {
        ...state,
        roles: action.payload.roles,
      };

    case ACTION_SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
      };

    default:
      return state;
  }
};

export default UserManagement;
