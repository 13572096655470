import {
  ACTION_LOGIN_USER,
  ACTION_REGISTER_USER,
  ACTION_API_ERROR,
  ACTION_LOGIN_USER_SUCCESSFULLY,
  ACTION_SET_PROFILE,
  ACTION_REGISTER_USER_SUCCESSFULLY,
} from './actionTypes';

export const loginUser = (user, navigate) => ({
  type: ACTION_LOGIN_USER,
  payload: { user, navigate },
});

export const loginUserSuccessfully = (user, navigate) => ({
  type: ACTION_LOGIN_USER_SUCCESSFULLY,
  payload: { user, navigate },
});

export const registerUser = (user, navigate) => ({
  type: ACTION_REGISTER_USER,
  payload: { user, navigate },
});

export const registerUserSuccessfully = (user, navigate) => ({
  type: ACTION_REGISTER_USER_SUCCESSFULLY,
  payload: { user, navigate },
});

export const apiError = (error) => ({
  type: ACTION_API_ERROR,
  payload: error,
});

export const setProfileEvent = (userData) => ({
  type: ACTION_SET_PROFILE,
  payload: { user: userData },
});
